<template>
    <div class="row">
        <label for="" class="col-sm-2">Date</label>
        <div class="col-sm-10">
            {{formatDate(log.created_at, 'dateTime')}}
        </div>
    </div>
    <div class="row">
        <label for="" class="col-sm-2">IP</label>
        <div class="col-sm-10">
        {{log.ip}}
        </div>
    </div>
    <div class="row">
        <label for="" class="col-sm-2">Useragent</label>
        <div class="col-sm-10">
        {{log.user_agent}}
        </div>
    </div>
    <div class="row">
        <label for="" class="col-sm-2">Class</label>
        <div class="col-sm-10">
        {{log.class}}
        </div>
    </div>
    <div class="row">
        <label for="" class="col-sm-2">Function</label>
        <div class="col-sm-10">
        {{log.function}}
        </div>
    </div>
    <div class="row">
        <label for="" class="col-sm-2">ID</label>
        <div class="col-sm-10">
        {{log.model_id}}
        </div>
    </div>
    <div class="row">
        <label for="" class="col-sm-2">User</label>
        <div class="col-sm-10">
        {{log.user.email}}
        </div>
    </div>
    <br>
    <h4>Information</h4>
    <pre>{{log.additional_information}}</pre>

    <br>
    <h4>Request</h4>
    <pre>{{log.request_body}}</pre>
</template>

<script>
    export default {
        name: 'LogForm',
        props: ['itemData'],
        data() {
            return {
                log: {
                    request_body: '',
                    user: {}
                },
                submitted: false,
                loading: false,
                invalid: {}

            }
        },
        watch: {
            itemData: function (val) {
                this.log = val    
            },
        },
        methods: {
            validate(){
                this.invalid = {};
                let valid = true;

                let required = document.querySelectorAll('[required]');

                // for(let field of required) {
                //     let fieldname = field.id;
                    
                //     if(!this.log[fieldname]) {
                //         this.invalid[fieldname] = true;
                //         valid = false;
                //     }
                // }

                // console.log("required", required);
                return valid;
            },
            getData() {
                return this.log;
            },
            setData(data) {
                this.log = data
            },
            reset() {
                this.log = {},
                this.loading = false
                this.invalid = {}
            }
        }        
    }
</script>
